export enum ViewConfigType {
    matterSummary = 1,
    leadSummary = 2,
    matterManagementSummary = 3,
    taskSummary = 4,
    referrer = 5,
    nameSummary = 6,
    matterSummaryDrilldown = 7,
    mailingParty = 8,
    invoiceSummary = 9,
    timesheetDrilldown = 10,
    staffBillingSummary = 11,
    brandSummary = 12,
    eventsAndCampaigns = 13,
    eventsAndCampaignsAnttendee = 14,
    userDashboardActivityList = 15,
    notesSummary = 16,
    notesSummaryDrilldown = 17,
    writeOffOnSummaryDrilldown = 18,
}